<template>
  <v-dialog v-model="dialogCustomer" persistent max-width="1000px">
    <v-card class="pa-0" elevation="2">
      <v-card-text class="pb-0">
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-container fluid>
            <!-- Entry Info section -->
            <v-row>
              <v-col cols="12" class="pa-0">
                <span class="display-2">
                  <v-icon left class="pb-1">mdi-apple-finder</v-icon
                  >{{ $vuetify.lang.t("$vuetify.CustomerList") }}</span
                >
              </v-col>
            </v-row>

            <v-sheet class="mt-5">
              <v-toolbar-items class="d-flex align-center">
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.search')"
                  v-model="search"
                  append-icon="mdi-magnify"
                />
              </v-toolbar-items>
            </v-sheet>

            <!-- Expandable Table -->
            <v-data-table
              :headers="headers"
              :single-select="singleSelect"
              :items="datas"
              :items-per-page="10"
              :search="search"
              item-class="primary--text"
              mobile-breakpoint="960"
              show-select
              v-model="selected"
            >
              
            </v-data-table>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="pb-8">
        <v-spacer />
        <v-btn color="red darken-2" outlined @click="cancelForm">{{
          $vuetify.lang.t("$vuetify.cancel")
        }}</v-btn>
        <v-btn color="blue darken-2" @click="handleSelectedData"> {{ $vuetify.lang.t("$vuetify.confirm") }}</v-btn>
        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { customerList } from "@/api/basicdata";

export default {
  name: "selectCustomer",
  props: { dialogCustomer: Boolean},
  methods: {
    cancelForm() {
      this.$emit("update:dialogCustomer", false);
    },
    reset() {
      this.$refs.form.reset();
    },
    getData() {
      this.tableLoading = true;
      customerList(this.searchForm)
        .then(data => {
          this.datas = [...data.items];
          this.tableLoading = false;
        })
        .catch(err => console.log(err));
    },
    handleSelectedData() {
      this.$emit('getselectedCustomer', this.selected)
      this.$emit("update:dialogCustomer", false);
    },
  },
  data() {
    return {
      selected: [],
      singleSelect: true,
      valid: false,
      types: ['All', 'A9 Online', 'Testing'],
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.itemNo"),
          value: "itemNo"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.customerName"),
          value: "company"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.ID"),
          value: "code" //Should be CompanyId
        },
        {
          text: this.$vuetify.lang.t("$vuetify.qty1"),
          value: "total_order"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.TotalAmount"),
          value: "total_money"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.contactPerson"),
          value: "contacts[0].contact"
        },
        {
          text: this.$vuetify.lang.t("$vuetify.mobile"),
          value: "contacts[0].tel" //should be phone
        }
      ],
      datas: [],
      search: "",
    };
  },
  mounted() {
    this.getData();
  },
};
</script>